<template>
  <div>
    <!--begin::Dashboard-->
    <div class="card full-height bg-ligth">
      <div class="card-header">
        <div class="form-group">
          <div class="row form-group">
            <div class="col-md-3">
              <label>Data inicial</label>
              <input
                class="form-control"
                type="date"
                v-model="form.data_inicial"
              />
            </div>
            <div class="col-md-3">
              <label>Data encerramento</label>
              <input
                class="form-control"
                type="date"
                v-model="form.data_final"
              />
            </div>

            <div style="margin-top: 25px" class="col-md-2">
              <button
                class="btn btn-warning"
                @click="listar_relatorio_funcionario()"
              >
                Pesquisar
              </button>
            </div>
            <div style="margin-top: 25px" class="col-md-2">
              <button
                class="btn btn-info"
                @click="listar_relatorio_funcionario_excel()"
              >
                Gerar excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row justify-content-center align-items-md-center">
      <div class="col-md-12">
        <div class="card full-height bg-ligth">
          <div class="card-header">
            <div class="row">
              <div class="col-md-8 col-8">
                <h3 class="mb-0">Relatorio Financeiro</h3>
              </div>

              <!-- <p>{{tipo == 'Inadiplicente' ? (lista_logs_pontos.valor_total | formatMoneyPY):''}} </p> -->
            </div>
          </div>
          <div class="card-body">
            <b-table
              :fields="['nome', 'sobrenome', 'pis', 'registro_ponto', 'acoes']"
              :items="lista_logs_pontos"
              :per-page="perPage"
              :current-page="currentPage"
              id="log s-table"
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
              "
              show-empty
              empty-text="Nenhum registro encontrado!"
            >
              <template #head(acoes)><span></span></template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="lista_logs_pontos.length"
              :per-page="perPage"
              aria-controls="logs-table"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import JwtService from "@/core/services/jwt.service";
  
export default {
  data() {
    return {
          currentPage: 1,
      perPage: 10,
      // status: 1,
      verif: false,
      form:{
        data_inicial:'',
        data_final:'',
        id_funcionario:null

      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Controle Relogio" }]);
  },
  // created() {
  //   this.lista_matriculas();
  // },
  computed: {
    lista_logs_pontos() {
      return this.$store.state.ponto.lista_registros;
    },
    id_fun() {
      return this.$store.state.ponto.id_fun;
    },
    //  lista_permissoes_filial(){
    //     return this.$store.state.sistema.lista_permissoes_filial;
    // }
  },

  created() {
    this.listar_logs_pontos();
  },
  methods: {
    async listar_logs_pontos() {
              const { id_funcionario } = this.$route.params;
     let a = {id:id_funcionario}
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("listar_registro_relogio_funcionario",a).finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");
      });
    },
    // atualizar(value) {
    //   this.$router.push({ name: "createEmpresa" });
    //   this.$store.dispatch("atualizar", value);
    // },

  async  listar_relatorio_funcionario(){
  
 this.$store.dispatch("limpar");
  
        const { id_funcionario } = this.$route.params;
        this.form.id_funcionario = id_funcionario
      await this.$store.dispatch("filtro_registros",this.form).finally(() => {
      });},

  async    listar_relatorio_funcionario_excel(){
         this.$store.dispatch("limpar");
  
        const { id_funcionario } = this.$route.params;
        this.form.id_funcionario = id_funcionario
        this.exportRelatorio()
      
  },
  exportRelatorio() {
    window.open(
        `${process.env.VUE_APP_API_URL}/usuario_ponto/registros/filtro/excel` +
        
          `?token=${JwtService.getToken()}&id_funcionario=${this.form.id_funcionario}&data_final=${this.form.data_final}&data_inicial=${this.form.data_inicial}`,
        "_blank"
      );

    }, 
}
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>